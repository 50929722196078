<template>
  <div
    class="flex flex-v flex-align-center flex-pack-center frame"
    style="height: 100%; padding-top: 60px;"
    ref="frame"
    :class="{ mobile: isMobile }"
  >
    <div class="login-container">
      <div class="login-wrap">
        <div class="login-tab">
          <div>
            <span
              v-bind:class="{ active: activeName == 'account' }"
              @click="activeName = 'account'"
              >手机号登录</span
            >
          </div>
          <div>
            <span
              v-bind:class="{ active: activeName == 'sms' }"
              @click="activeName = 'sms'"
              >短信登录</span
            >
          </div>
        </div>
        <div style="margin-top: 30px">
            <AccountLogin
              v-if="activeName == 'account'"
              @success="onLoginSuccess"
              @register="onRegister"
              @onForget="onForget"
            ></AccountLogin>
            <SmsLogin
              v-if="activeName == 'sms'"
              :phone="phone"
              @success="onLoginSuccess"
              @register="onRegister"
              @onForget="onForget"
            ></SmsLogin>
          </div>
      </div>
      <div class="login-right">
        <h3>Hello!</h3>
        <div>欢迎登陆<br />家具企业制造执行运营管理系统</div>
      </div>
    </div>
    <div v-if="false">
      <div class="login-title">欢迎登录</div>
      <div class="login flex">
        <div class="login-left-bg">
          <img src="../../assets/images/login/denglu_bg.jpg" alt="" />
        </div>
        <div class="login-right flex-1">
          <div class="flex flex-justify-around login-tab">
            <div
              v-bind:class="{ active: activeName == 'account' }"
              @click="activeName = 'account'"
            >
              账号登录
            </div>
            <div
              v-bind:class="{ active: activeName == 'sms' }"
              @click="activeName = 'sms'"
            >
              短信登录
            </div>
          </div>
          <div style="margin-top: 30px">
            <AccountLogin
              v-if="activeName == 'account'"
              @success="onLoginSuccess"
              @register="onRegister"
              @onForget="onForget"
            ></AccountLogin>
            <SmsLogin
              v-if="activeName == 'sms'"
              :phone="phone"
              @success="onLoginSuccess"
              @register="onRegister"
              @onForget="onForget"
            ></SmsLogin>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { quit } from "@/service/account";
import AccountLogin from "@/components/login/account.vue";
import SmsLogin from "@/components/login/sms.vue";
export default {
  components: { AccountLogin, SmsLogin },
  data() {
    return {
      activeName: "account",
      phone: null,
      isMobile: false,
    };
  },
  created() {
    if (this.$route.query.type) {
      this.activeName = this.$route.query.type;
      this.phone = this.$route.query.phone;
    }
  },
  mounted() {
    this.isMobile = this.$refs["frame"].offsetWidth < 600;
    window.onresize = () => {
      this.isMobile = this.$refs["frame"].offsetWidth < 600;
    };
    // console.log(decodeURI(this.$route.query.p_url).split('?'));
    // if (this.$route.query.portal) {
    //     quit().then();
    // }
  },
  methods: {
    onLoginSuccess() {
      // 配合对接mes门户
      // if (this.$route.query.p_url) {
      //     let url = decodeURI(this.$route.query.p_url);
      //     if (url.split('?').length>1) {
      //         console.log(url+'&p_token='+this.$store.state.loginUser.token);
      //         // window.open(url+'&p_token='+this.$store.state.loginUser.token,'_self');
      //         window.location.href = url+'&p_token='+this.$store.state.loginUser.token;
      //     } else {
      //         console.log(url+'?p_token='+this.$store.state.loginUser.token);
      //         // window.open(url+'?p_token='+this.$store.state.loginUser.token,'_self');
      //         window.location.href = url+'?p_token='+this.$store.state.loginUser.token;
      //     }
      //     if (this.$route.query.portal) {
      //         quit().then();
      //     }
      // } else {
      //     this.$router.push('/center');
      // }
      // 适配供应商角色
      if (this.$store.state.loginUser.comp.comp_type == 6) {
        this.$router.push("/supplier");
      } else if (this.$store.state.loginUser.comp.comp_type == 7) {
        this.$router.push("/cloudLogistic");
      } else {
        this.$router.push("/mes");
      }
    },
    onRegister() {
      if (this.isMobile) {
        this.$router.push("/mobile/register");
      } else {
        this.$router.push("/register");
      }
    },
    onForget() {
      if (this.isMobile) {
        this.$router.push("/mobile/forget");
      } else {
        this.$router.push("/forget");
      }
    },
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>
<style scoped lang="less">
  .frame {
    background: url("../../assets/images/login/login-bg.jpg") center center no-repeat;
    background-size: 100% 100%;
  }
.login-right {
  position: absolute;
  z-index: 0;
  top: 50%;
  width: 811px;
  height: 600px;
  background: #1b2952;
  margin-top: -300px;
  right: 0;
  padding: 75px 0 75px 194px;
  h3 {
    color: rgba(62, 197, 239, 1);
    font-size: 32px;
  }
  div {
    font-size: 40px;
    color: #fff;
  }
}
.login-wrap {
  width: 417px;
  height: 520px;
  background: #f9f9f9;
  border: 1px solid #979797;
  position: relative;
  z-index: 1;
  padding: 80px 88px;
  .login-tab {
    display: flex;
    > div {
      flex: 1;
      text-align: center;
      padding-bottom: 2px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      font-weight: 500;
      span.active {
        display: inline-block;
        color: rgba(21, 35, 72, 1);
        border-bottom: 2px solid #152348;
      }
    }
  }
}
.login-container {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
</style>